import React from 'react'
import Link from 'next/link'
import PropTypes from 'prop-types'
import config from 'SRC/config/config.yaml'
import ReactSVG from 'react-svg'

export const Item = ({current, seo, id, title, shortTitle, icon, iconSvg, currentParentCategory}) => {
  let flexGrow = 3
  if (title.length <= 13) {
    flexGrow = 3
  } else if (title.length <= 16) {
    flexGrow = 4
  } else {
    flexGrow = 5
  }

  const href = currentParentCategory ? `/category?parentCategory=${currentParentCategory.seo}&category=${seo}` : ''
  const as = currentParentCategory ? `/${currentParentCategory.seo}/${seo}` : ''

  return <div className={`kategorije-item ${current && id === current.id ? 'active' : ''} `} style={{ flexGrow }}>
    <Link href={href} as={as}>
      <a>
        {icon ? <i className={icon} /> : null}
        {!icon && iconSvg ? <div className='kategorije-item-icon-svg'>
          <ReactSVG src={`${config.mediaCDN}${iconSvg}`} beforeInjection={svg => {
            svg.setAttribute('style', 'height: 100%;')
          }} wrapper='span' />
        </div> : null}
        <p>{shortTitle || title}</p>
      </a>
    </Link>
  </div>
}

Item.propTypes = {
  current: PropTypes.object,
  seo: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  shortTitle: PropTypes.string,
  icon: PropTypes.string,
  iconSvg: PropTypes.string,
  currentParentCategory: PropTypes.object.isRequired
}
