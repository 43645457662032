import React from 'react'
import { Item } from '../Item'
import PropTypes from 'prop-types'

export const List = ({items, currentSubGroup, className}) => {
  return (
    <div className={className}>
      {
        items.filter(item => !item.hideIcon).map(item => {
          return <Item {...item} current={currentSubGroup} key={item.id} />
        })
      }
    </div>
  )
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    hideIcon: PropTypes.bool
  })).isRequired,
  currentSubGroup: PropTypes.object,
  className: PropTypes.string
}

List.defaultProps = {
  items: []
}
