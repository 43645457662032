import React from 'react'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import Router, { withRouter } from 'next/router'
import Link from 'next/link'
import { debounce } from 'throttle-debounce'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { getInfo } from 'SRC/modules/users/detail/selectors'
import { getSearchAsUrl, getSearchHrefUrl, prepareFilterValues } from 'SRC/modules/ads/filter/functions'
import config from 'SRC/config/config.yaml'

export class PanelMobile extends React.PureComponent {
  constructor (props) {
    super(props)

    this.items = config.adsList.panelItemsMobile

    this.state = {
      visible: true
    }
  }

  componentDidMount = () => {
    document.addEventListener('scroll', debounce(150, this.updateVisibility))
  }

  componentWillUnmount = () => {
    document.removeEventListener('scroll', this.updateVisibility)
  }

  updateVisibility = () => {
    const footerEl = document.querySelector('.footer-main')
    const footerOffsetTop = footerEl.offsetTop

    const scrollPosition = window.scrollY || document.body.scrollTop

    const diff = footerOffsetTop - scrollPosition - window.visualViewport.height

    if (diff >= 0) this.setState({ visible: true })
    else this.setState({ visible: false })
  }

  onPanelItemClick = e => {
    const key = Number(e.currentTarget.dataset.id)

    const {
      filterValues,
      currentParentCategory,
      currentSubcategory,
      currentGroup,
      baseAs,
      baseHrefQuery
    } = this.props

    const preparedFilterValues = prepareFilterValues(filterValues)

    // Default sortBy param
    if (!preparedFilterValues.hasOwnProperty('sortBy')) {
      preparedFilterValues.sortBy = 'dateDesc'
    }

    const panelFilter = this.items[key]

    // set panel filter value or unset if it exists
    if (preparedFilterValues.hasOwnProperty(panelFilter.code)) {
      try {
        if (preparedFilterValues[panelFilter.code] === JSON.parse(panelFilter.value)) {
          delete preparedFilterValues[panelFilter.code]
        } else {
          preparedFilterValues[panelFilter.code] = JSON.parse(panelFilter.value)
        }
      } catch (err) {
        if (preparedFilterValues[panelFilter.code] === panelFilter.value) {
          delete preparedFilterValues[panelFilter.code]
        } else {
          preparedFilterValues[panelFilter.code] = panelFilter.value
        }
      }
    } else {
      try {
        preparedFilterValues[panelFilter.code] = JSON.parse(panelFilter.value)
      } catch (err) {
        preparedFilterValues[panelFilter.code] = panelFilter.value
      }
    }

    let actualSubcategorySeo = null

    if (currentSubcategory) actualSubcategorySeo = currentSubcategory.seo
    else actualSubcategorySeo = currentGroup ? currentGroup.seo : null

    const hrefUrl = getSearchHrefUrl()
    const href = {
      pathname: hrefUrl,
      query: {
        ...baseHrefQuery,
        ...preparedFilterValues,
        parentCategory: currentParentCategory ? currentParentCategory.seo : null,
        category: actualSubcategorySeo,
        pageNumber: 1
      }
    }

    const asUrl = getSearchAsUrl(currentParentCategory ? currentParentCategory.seo : null, actualSubcategorySeo, baseAs)
    const as = {
      pathname: asUrl,
      query: { ...preparedFilterValues, pageNumber: 1 }
    }

    Router.push(href, as)
  }

  getIsActive = itemId => {
    const { filterValues } = this.props
    const item = this.items[itemId]

    let isActive = false

    if (filterValues.hasOwnProperty(item.code)) {
      try {
        if (filterValues[item.code] === JSON.parse(item.value)) {
          isActive = true
        }
      } catch (err) {
        if (filterValues[item.code] === item.value) {
          isActive = true
        }
      }
    }

    return isActive
  }

  renderItem = key => {
    const item = this.items[key]

    if (item.link) {
      const { router } = this.props

      const isActive = item.code === 'all-ads' && router.pathname === '/'

      return (
        <Link href={item.link.href} as={item.link.as}>
          <a className={isActive ? 'active' : ''}>
            {item.iconSvg
              ? (
                <ReactSVG src={item.iconSvg} beforeInjection={svg => {
                  svg.setAttribute('style', 'width: 5vh; height: 5vh;')
                }} />
              ) : <i className={`${item.classname} ico`} />
            }
            <span>{item.label}</span>
          </a>
        </Link>
      )
    }

    return (
      <a
        href='javascript:void(0)'
        onClick={this.onPanelItemClick}
        data-id={key}
        className={this.getIsActive(key) ? `active` : ``}
      >
        <i className={`${item.classname} ico`} />
        <span>{item.label}</span>
      </a>
    )
  }

  render () {
    const { visible } = this.state

    return (
      <div className='oglasi-filter-content-mobile' style={{ display: visible ? 'block' : 'none' }}>
        <ul>
          {Object.keys(this.items).map(key => <li key={key}>{this.renderItem(key)}</li>)}
        </ul>
      </div>
    )
  }
}

const mapStateToProps = (state, ownProps) => {
  const user = getInfo(state)
  
  let baseAs = '/'
  let baseHrefQuery = {}

  if (ownProps.isUserSearch && user && user.type && user.username) {
    baseAs = `/${user.type.seo}/${user.seo || user.username}/`
    baseHrefQuery = { username: user.seo || user.username, userType: user.type.seo }
  }

  return {
    currentSubcategory: getCurrentSubCategory(state),
    currentParentCategory: getParentCategory(state),
    currentGroup: getCurrentGroup(state),
    baseAs,
    baseHrefQuery
  }
}

PanelMobile.propTypes = {
  baseAs: PropTypes.string,
  baseHrefQuery: PropTypes.object,
  filterValues: PropTypes.object.isRequired,
  currentSubcategory: PropTypes.object,
  currentParentCategory: PropTypes.object,
  currentGroup: PropTypes.object
}

PanelMobile.defaultProps = {
  filterValues: {}
}

const enhance = compose(
  withRouter,
  connect(mapStateToProps)
)
export default enhance(PanelMobile)
