import React from 'react'
import { connect } from 'react-redux'
import { Item } from '../Item'
import PropTypes from 'prop-types'
import { getCurrentGroup, getGroups } from 'SRC/modules/groups/selectors'
import { getParentCategory } from 'SRC/modules/categories/selectors'

const List = ({ items, currentGroup, currentParentCategory }) => {
  return (
    <div className='dynamic-groups-items'>
      {items && Array.isArray(items) ? items.map(item => (
        <Item
          {...item}
          currentGroup={currentGroup}
          currentParentCategory={currentParentCategory}
          key={item.id}
        />
      )) : null}
    </div>
  )
}

const mapStateToProps = state => {
  const currentParentCategory = getParentCategory(state)

  return {
    items: getGroups(state),
    currentParentCategory,
    currentGroup: getCurrentGroup(state)
  }
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentParentCategory: PropTypes.object.isRequired
}

List.defaultProps = {
  items: []
}

export default connect(mapStateToProps)(List)
