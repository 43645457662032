import React from 'react'
import Link from 'next/link'
import { connect } from 'react-redux'
import { getIsTransitioning } from 'SRC/modules/common/selectors'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'
import config from 'SRC/config/config.yaml'

export const Item = ({ id, icon, iconSvg, seo, title, currentGroup, currentParentCategory, isTransitioning }) => {
  let flexGrow = 3
  if (title.length <= 13) {
    flexGrow = 3
  } else if (title.length <= 16) {
    flexGrow = 4
  } else {
    flexGrow = 5
  }

  const isActive = currentGroup && seo === currentGroup.seo

  if (!isTransitioning || isActive) {
    const href = currentParentCategory ? `/category?parentCategory=${currentParentCategory.seo}&category=${seo}` : ''
    const as = currentParentCategory ? `/${currentParentCategory.seo}/${seo}` : ''

    return (
      <div className={`kategorije-item kategorije-item-group ${isActive ? 'active' : ''}`} style={{ flexGrow }}>
        <Link href={href} as={as}>
          <a>
            {icon ? <i className={icon} /> : null}
            {!icon && iconSvg ? <div className='kategorije-item-icon-svg'>
              <ReactSVG src={`${config.mediaCDN}${iconSvg}`} beforeInjection={svg => {
                svg.setAttribute('style', 'max-width: 65px; height: 40px;')
              }} wrapper='span' />
            </div> : null}
            <p>{title}</p>
          </a>
        </Link>
      </div>
    )
  }

  return (
    <div className={`kategorije-item kategorije-item-group disabled`} style={{ flexGrow }}>
      <a href='javascript:void(0)'>
        {icon ? <i className={icon} /> : null}
        {!icon && iconSvg ? <div className='kategorije-item-icon-svg'>
          <ReactSVG src={`${config.mediaCDN}${iconSvg}`} beforeInjection={svg => {
            svg.setAttribute('style', 'max-width: 65px; height: 40px;')
          }} wrapper='span' />
        </div> : null}
        <p>{title}</p>
      </a>
    </div>
  )
}

Item.propTypes = {
  id: PropTypes.number.isRequired,
  icon: PropTypes.string,
  iconSvg: PropTypes.string,
  seo: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  currentGroup: PropTypes.object.isRequired,
  currentParentCategory: PropTypes.object.isRequired,
  isTransitioning: PropTypes.bool.isRequired
}

const mapStateToProps = state => ({
  isTransitioning: getIsTransitioning(state)
})

export default connect(mapStateToProps)(Item)
