import React from 'react'
import PropTypes from 'prop-types'
import { compose } from 'recompose'
import { connect } from 'react-redux'
import { withRouter } from 'next/router'
import { DynamicGroupsList } from 'SRC/modules/groups/components'
import { ParentCategoriesMenu, SubcategoriesHorizontalMenu } from 'SRC/modules/categories/components'
import { getPaginationParams } from 'SRC/modules/ads/pagination/selectors'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getParentCategory, getCurrentSubCategory } from 'SRC/modules/categories/selectors'
import { SecureShoppingBox } from 'SRC/modules/common/components/SecureShoppingBox'
import { Form } from '../Form'

export const Container = ({
  currentGroup,
  currentSubcategory,
  currentParentCategory,
  router,
  formStyle,
  paginationParams,
  filterValues,
  isAdPage
}) => {
  const searchItems = { text: false, categories: false }

  if (currentGroup) {
    if (router.pathname === '/search') {
      searchItems.text = true
      searchItems.categories = false
    }

    if (currentGroup.categories && (currentGroup.categories.length > 1 ||
      (currentGroup.categories.length === 1 && currentGroup.categories[0].seo !== currentGroup.seo))
    ) {
      const isGroupWithHiddenCategories = currentGroup.categories.every(cat => cat.hideIcon)

      if (isGroupWithHiddenCategories) {
        searchItems.text = true
        searchItems.categories = true
      }
    }
  }

  return <div className={`ogl-header ${isAdPage ? 'ogl-header__ad-details' : ''}`}>
    <ParentCategoriesMenu />
  
    {!isAdPage ? (
      <section className='ogl-header-main'>
        {!currentParentCategory?.isSelfContainedParentCategory ? <DynamicGroupsList /> : null}

        <SubcategoriesHorizontalMenu />

        <div className={`kategorije-pretraga`}>
          <Form
            currentPage={paginationParams.currentPage}
            filterValues={filterValues}
            formStyle={formStyle || 'basic'}
            currentParentCategory={currentParentCategory}
            currentSubcategory={currentSubcategory}
            currentGroup={currentGroup}
          />
        </div>
      </section>
    ) : null}

    {/* <SecureShoppingBox /> */}
  </div>
}

const mapStateToProps = (state) => {
  return {
    currentGroup: getCurrentGroup(state),
    currentSubcategory: getCurrentSubCategory(state),
    currentParentCategory: getParentCategory(state),
    paginationParams: getPaginationParams(state)
  }
}

Container.propTypes = {
  currentGroup: PropTypes.shape({
    categories: PropTypes.array,
    subClass: PropTypes.string,
    seo: PropTypes.string
  }),
  isAdPage: PropTypes.bool.isRequired,
  currentSubcategory: PropTypes.object,
  currentParentCategory: PropTypes.object,
  paginationParams: PropTypes.shape({
    currentPage: PropTypes.number
  }).isRequired,
  filterValues: PropTypes.object,
  formStyle: PropTypes.string,
  router: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}

Container.defaultProps = {
  isAdPage: false
}

export default compose(withRouter, connect(mapStateToProps))(Container)
