import React from 'react'
import { connect } from 'react-redux'
import { Item } from '../Item'
import PropTypes from 'prop-types'
import { getCurrentGroup } from 'SRC/modules/groups/selectors'
import { getCurrentSubCategory, getParentCategory } from 'SRC/modules/categories/selectors'

const List = ({currentGroup, currentParentCategory, className, currentSubCategory}) => {
  return (
    <div className={className}>
      {
        currentGroup && currentGroup.categories && currentGroup.categories.length
          ? currentGroup.categories.filter(item => item && !item.hideIcon)
            .slice().sort((itemA, itemB) => itemA.weight - itemB.weight).map(item => {
              return <Item {...item} current={currentSubCategory} currentParentCategory={currentParentCategory} key={item.id} />
            })
          : null
      }
    </div>
  )
}

const mapStateToProps = state => {
  return {
    currentGroup: getCurrentGroup(state),
    currentSubCategory: getCurrentSubCategory(state),
    currentParentCategory: getParentCategory(state)
  }
}

List.propTypes = {
  currentGroup: PropTypes.object.isRequired,
  currentSubCategory: PropTypes.object.isRequired,
  currentParentCategory: PropTypes.object.isRequired,
  className: PropTypes.string
}

List.defaultProps = {
  items: []
}

export default connect(mapStateToProps)(List)
