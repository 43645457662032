import React from 'react'
import { Item } from '../Item'
import PropTypes from 'prop-types'

export const List = ({items, currentGroup}) => {
  return (
    <div className='category-items'>
      {
        items && Array.isArray(items)
          ? items.map(item => {
            return <Item
              {...item}
              currentGroup={currentGroup}
              key={item.id}
            />
          })
          : null
      }
    </div>
  )
}

List.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    icon: PropTypes.string,
    seo: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  })).isRequired,
  currentGroup: PropTypes.object
}

List.defaultProps = {
  items: []
}
